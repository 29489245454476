import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="footerContainer">
      <img
        src={"/logos/vacalogonegro.svg"}
        alt={"logo vaca.do"}
        style={{ height: 40, marginBottom: 10 }}
      />
      <p>
        Somos una agencia de Vacaciones Online que proporciona soluciones de
        vacaciones completas al momento de planificar y reservar tus vacaciones
        en la Republica Dominicana o en el exterior.
      </p>
      <br />
      <div className="footerBody">
        <div>
          <h3>Contáctanos</h3>
          <p>
            Ave. Winston Churchill #1550,
            <br />
            Plaza New Orleans, Suite 208 Urb. <br />
            Fernández, Santo Domingo
          </p>
          <p>809-616-3000</p>
          <p>info@vaca.do</p>
        </div>  
        <div>
          <h3>Servicios</h3>
          <Link to="/Contact" state={{ page: "hoteles", align: "flex-end" }}>
            <p>Hoteles</p>
          </Link>
          <Link
            to="/Contact"
            state={{ page: "excursiones", align: "flex-start" }}
          >
            <p>Excusiones</p>
          </Link>
          <Link
            to="/Contact"
            state={{ page: "vuelos", align: "flex-end" }}
          >
          <p>Boletos aereos</p>
          </Link>
          <Link to="/Contact"  state={{ page: "cruceros", align: "flex-start" }}>
          <p>Cruceros</p>
          </Link>
          <Link
            to="/Contact"
            state={{ page: "vuelos", align: "flex-end" }}
          >
          <p>Vuelos a Miami</p>
          </Link>
        </div>
        <div>
          <h3>Informaciones</h3>
          <p>Terminos de uso</p>
          <p>Sobre nosotros</p>
          <p>Politicas de Privacidad</p>
          <p>Contáctanos</p>
        </div>
        {/* <h3>Verificaciones</h3> */}
      </div>
      <hr />
      <h4
        style={{
          fontSize: 15,
          marginTop: 10,
          marginBottom: 10,
          borderTopColor: "gray",
          borderTopWidth: 1,
        }}
      >
        Copyright © 2023 - Una marca de Estilo Travel S.R.L.
      </h4>
    </footer>
  );
};

export default Footer;

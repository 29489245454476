import React, { useState } from "react";
import "./ContactForm.css";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import axios from "axios";

const ContactForm = ({ from }: any) => {
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const validate = () => {
      const expression =
        /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

      return expression.test(String(inputs.email).toLowerCase());
    };
    //   emailjs.send('<YOUR_SERVICE_ID>','<YOUR_TEMPLATE_ID>', templateParams, '<YOUR_PUBLIC_KEY>')
    if (
      inputs.name !== "" &&
      inputs.email !== "" &&
      inputs.message !== "" &&
      inputs.phone !== "" &&
      validate() === true
    ) {
      const options = {
        method: "POST",
        url: "https://damp-badlands-60972.herokuapp.com/api/email/post",
        headers: { "Content-Type": "application/json" },
        data: {
          name: inputs.name,
          mail: 'info@vaca.do',
          message:  inputs.message,
          from: inputs.email,
          phone: inputs.phone,
          pwd: "vacado01",
        },
      };

      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
          setInputs({ name: "", email: "", message: "", phone: "" });
          Swal.fire({
            title: "Exito",
            text: "Mensaje enviado correctamente, nos contactaremos lo antes posible",
            icon: "success",
            confirmButtonText: "OK",
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      Swal.fire({
        title: "Error",
        text: "Por favor, complete todos los campos correctamne",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div style={{height: '100%', backgroundColor:'white'}}>
      <form className="ContactFormContainer" onSubmit={handleSubmit}>
        <h1
          style={{
            fontWeight: "300",
            color: " #FDB54E",
            fontFamily: "Fall in love",
          }}
        >
          Contáctanos
        </h1>
        <br />
        <div>
          <label>Nombre :</label>
        </div>

        <input
          name="name"
          type="text"
          value={inputs.name}
          onChange={handleInputChange}
        />
         <label>Telefono :</label>
        <input
          name="phone"
          type="phone"
          value={inputs.phone}
          onChange={handleInputChange}
        />
        <label>Email :</label>
        <input
          name="email"
          type="text"
          value={inputs.email}
          onChange={handleInputChange}
        />
        <label>Mensaje :</label>
        <textarea
          name="message"
          value={inputs.message}
          onChange={handleInputChange}
        ></textarea>
        {/* <input
          type="text"
          value={inputs.message}
          onChange={handleInputChange}
          placeholder="Mensaje"
          name="message"
          /> */}
        <input type="submit" value="Enviar" />
      </form>
    </div>
  );
};

export default ContactForm;

import React, { useEffect } from "react";
import BannerPromotion from "../../components/BannerPromotion/BannerPromotion";
import HeaderPage from "../../components/Header/HeaderPage";
import hotelBannerImg from "../../assets/hoteles.png";
import hotelIcon from "../../assets/hotelIcon.svg";
import "./Contact.css";
import ServiceIcon from "../../components/ServicesIcons/ServiceIcon";
import PlaneIcon from "../../assets/iconPlane.svg";
import hotelIcon2 from "../../assets/hotelIcon2.svg";
import DisneyIcon from "../../assets/disneyIcon.svg";
import CruceroIcon from "../../assets/cruceroIcon.svg";
import OfertasIcon from "../../assets/OfertasIcon.svg";
import ExcursionesIcon from "../../assets/excursionIcon.svg";
import FerrieIcon from "../../assets/FerrieIcon.svg";
import InsuranceIcon from "../../assets/insuranceIcon.svg";
import PromotionBanner from "../../components/PromotionBanner/PromotionBanner";
import CruceroBanner from "../../assets/crucerosbanner.png";
import DisneyBanner from "../../assets/disney.png";
import PlaneBanner from "../../assets/plane.png";
import ExcursionesBanner from "../../assets/excursiones.png";
import OfertasBanner from "../../assets/ofertas.png";
import FerrieBanner from "../../assets/ferrie.png";
import OfertasBanner2 from "../../assets/ofertas2.png";
import CruceroIcon2 from "../../assets/cruceroIcon2.svg";
import DisneyIcon2 from "../../assets/disneyIcon2.svg";
import ExcusionesIcon2 from "../../assets/excursionIcon2.svg";
import OfertasIcon2 from "../../assets/ofertasIcon2.svg";
import FerriIcon2 from "../../assets/FerrieIcon2Banner.svg";

import Footer from "../../components/Footer/Footer";
import { useLocation } from "react-router-dom";
import ContactForm from "../../components/ContactForm/ContactForm";

const Contact = () => {
  const location = useLocation();
  const data = location.state;
  const align = data?.align || "start";
  useEffect(() => {
    window.scroll({
      top: 100,
      left: 100,
      behavior: 'smooth'
    });
  }, [data.page]);
  return (
    <div>
      <HeaderPage />
      <div className="container">
        {data?.page === "hoteles" ? (
          <BannerPromotion
            title={"Hoteles"}
            img={hotelBannerImg}
            icon={hotelIcon}
            background={"#707070"}
            text={
              "Te ofrecemos una amplia selección de hoteles para que puedas planificar tu próxima estadía de acuerdo a tus gustos y necesidades. Disponemos de opciones para todos los presupuestos, desde hoteles económicos hasta hoteles de lujo con todas las comodidades. Además, podrás elegir entre diferentes tipos de alojamiento, como habitaciones estándar, suites y villas. Todos nuestros hoteles cuentan con ubicaciones privilegiadas, excelentes servicios y atención al cliente de primer nivel para que tu experiencia sea única e inolvidable."
            }
            imageRate={1}
            contenRate={1}
            direction={"row"}
            full={true}
            position={"left"}
            borderRadius={10}
            containerStyle={{ marginTop: 20 }}
          />
        ) : null}
        {data?.page === "cruceros" ? (
          <BannerPromotion
            title={"Cruceros"}
            img={CruceroBanner}
            icon={CruceroIcon2}
            background={"#055C87"}
            text={
              "Estamos comprometidos en ayudarte a encontrar la aventura en alta mar que siempre has deseado. Con nuestra amplia selección de opciones de cruceros, podemos ayudarte a crear ese momento mágico que estás buscando. ¡Permítenos ayudarte a planificar tu próxima aventura en alta mar y hacer que sea inolvidable!"
            }
            imageRate={1.8}
            contenRate={1}
            direction={"row-reverse"}
            full={true}
            position={"right"}
            borderRadius={10}
            containerStyle={{ marginTop: 20 }}
          />
        ) : null}
        {data?.page === "disney" ? (
          <BannerPromotion
            title={"Disney"}
            img={DisneyBanner}
            icon={DisneyIcon2}
            background={"#000"}
            text={
              "¡Haz realidad tus sueños en el lugar más mágico del mundo! En nuestra agencia de viajes, te ofrecemos una experiencia única e inolvidable en Disney. Podrás disfrutar de todos los parques temáticos, como el famoso Magic Kingdom, el Hollywood Studios, el Epcot y el Animal Kingdom, así como de los hoteles y resorts de Disney, cada uno con su propia temática y ambiente únicos. También tendrás la oportunidad de conocer a tus personajes favoritos de Disney y disfrutar de sus espectáculos en vivo. Además, te ofrecemos paquetes completos que incluyen el alojamiento, los traslados y las entradas a los parques temáticos, para que no tengas que preocuparte por nada y solo te dediques a disfrutar de la magia de Disney. ¡No esperes más para vivir una experiencia única e inolvidable con nosotros en Disney!"
            }
            imageRate={1}
            contenRate={1}
            direction={"row"}
            full={true}
            position={"14%"}
            borderRadius={10}
            containerStyle={{ marginTop: 20 }}
          />
        ) : null}
        {data?.page === "excursiones" ? (
          <BannerPromotion
            title={"Excursiones"}
            img={ExcursionesBanner}
            icon={ExcusionesIcon2}
            background={"#57A8EA"}
            text={
              "Descubre los lugares más increíbles con nuestras excursiones guiadas. Te ofrecemos una amplia variedad de opciones de excursiones para que puedas explorar cada destino al máximo. Desde tours culturales y históricos, hasta excursiones de aventura y deportes extremos, tenemos opciones para todos los gustos y edades. También podrás disfrutar de la comodidad de nuestros transportes privados para moverte de un lugar a otro con facilidad. Con nuestras excursiones guiadas, estamos seguros de que vivirás experiencias únicas e inolvidables en cada destino que visites. ¡No esperes más para explorar el mundo con nosotros!"
            }
            imageRate={1}
            contenRate={1}
            direction={"row-reverse"}
            full={true}
            position={"right"}
            borderRadius={10}
            containerStyle={{ marginTop: 20 }}
          />
        ) : null}
        {data?.page === "vuelos" ? (
          <BannerPromotion
            title={"Vuelos"}
            img={PlaneBanner}
            icon={PlaneIcon}
            background={"#fff"}
            color={"#000"}
            text={
"Nos aseguramos de que tu viaje sea lo más cómodo y conveniente posible. Con nuestra amplia selección de opciones de vuelos, podemos ayudarte a acercar las distancias y llevarte a los lugares que siempre has deseado visitar. Nuestro equipo de expertos en viajes se encargará de todo, desde la reserva de tus boletos de avión hasta la coordinación de tus traslados y alojamiento. Además, trabajamos con las aerolíneas más reconocidas del mundo para garantizarte un viaje seguro y cómodo. Ya sea que estés planeando unas vacaciones en familia, una escapada romántica o un viaje de negocios, estamos aquí para ayudarte a encontrar la mejor opción de vuelo que se adapte a tus necesidades y presupuesto. ¡Permítenos hacer que tu próximo viaje sea una experiencia inolvidable desde el momento en que reservas tus boletos aéreos con nosotros!"            }
            imageRate={1}
            contenRate={1}
            direction={"row"}
            full={true}
            textPosition={"left"}
            borderRadius={10}
            containerStyle={{ marginTop: 20 }}
          />
        ) : null}
        {data?.page === "ofertas" ? (
          <BannerPromotion
            title={"Ofertas"}
            img={OfertasBanner}
            icon={OfertasIcon2}
            background={"#141416"}
            position={"center"}
            text={
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            }
            imageRate={1}
            contenRate={1.5}
            direction={"row-reverse"}
            full={true}
            borderRadius={10}
            containerStyle={{ marginTop: 20 }}
          />
        ) : null}
        {data?.page === "ferrie" ? (
          <BannerPromotion
            title={"Ferrie"}
            img={FerrieBanner}
            icon={FerriIcon2}
            background={"#1E324A"}
            text={
              "¿Estás buscando una forma emocionante y diferente de explorar el Caribe? ¡Nuestros ferries son la respuesta! En nuestra agencia de viajes, te ofrecemos una experiencia única y emocionante en ferry para que puedas descubrir todas las maravillas que el Caribe tiene para ofrecer. Con nuestros modernos ferries, podrás disfrutar de un viaje cómodo y seguro mientras admiras las impresionantes vistas de las aguas turquesas y las hermosas playas de la región. También te ofrecemos una variedad de opciones de servicios a bordo, como comidas y bebidas, para que puedas disfrutar de un viaje relajante y sin preocupaciones. ¡No esperes más para vivir una experiencia única e inolvidable con nosotros en Ferries del Caribe!"
            }
            imageRate={1}
            contenRate={1}
            direction={"row"}
            full={true}
            borderRadius={10}
            containerStyle={{ marginTop: 20 }}
          />
        ) : null}
        {data?.page === "ofertas2" ? (
          <BannerPromotion
            title={"Ofertas"}
            img={OfertasBanner2}
            icon={hotelIcon}
            background={"#141416"}
            text={
              "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            }
            imageRate={1}
            contenRate={1.5}
            direction={"row-reverse"}
            full={true}
            position={"75%"}
            borderRadius={10}
            containerStyle={{ marginTop: 20 }}
          />
        ) : null}

        <div
          className="contactForm"
          style={{ alignItems: align, padding: "0 10px 0 -10px" }}
        >
          <div className="containerContactForm">
            <ContactForm from={data?.page}/>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            rowGap: 40,
            marginBottom: 40,
            justifyItems: "center",
          }}
        >
          <ServiceIcon
            title="Vuelos"
            icon={PlaneIcon}
            state={{ page: "vuelos", align: "flex-end" }}
          />
          <ServiceIcon
            title="Hoteles"
            icon={hotelIcon2}
            state={{ page: "hoteles", align: "flex-end" }}
          />
          <ServiceIcon
            title="Disney"
            icon={DisneyIcon}
            state={{ page: "disney", align: "flex-end" }}
          />
          <ServiceIcon
            title="Cruceros"
            icon={CruceroIcon}
            state={{ page: "cruceros", align: "flex-start" }}
          />
          <ServiceIcon
            title="Ofertas"
            icon={OfertasIcon}
            state={{ page: "ofertas", align: "flex-end" }}
          />
          <ServiceIcon
            title="Excusiones"
            icon={ExcursionesIcon}
            state={{ page: "excursiones", align: "flex-start" }}
          />        
          <ServiceIcon
            title="Ferrie"
            icon={FerrieIcon}
            state={{ page: "ferrie", align: "flex-end" }}
          />
          <ServiceIcon
            title="Seguros"
            icon={InsuranceIcon}
            state={{ page: "ofertas2", align: "flex-start" }}
          />
        </div>
        <br />
        <br />
        <br />
        <article className="AppSuscribe">
          <PromotionBanner />
        </article>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;

import React from "react";
import "./PromotionBanner.css";
const PromotionBanner = () => {
  return (
    <div className="promotionBannerContainer">
      {/* <img src={"/collage.png"} alt={"promotion"} /> */}
      <div className="promotionBannerImg"></div>
      <div className="promotionBannerContent">
        <h2>Armamos tu paquete</h2>
        <p>
          En Vaca nos importa, y es por eso que te escuchamos y armamos un
          paquete especial orientado directamente a tus necesidades.
        </p>
      </div>
    </div>
  );
};
export default PromotionBanner;
